<template>
  <div class="relative-center w1400 mt-6 z-200">
    <div class="h-100vh w-100 fixed l-0 r-0 z-200" v-if="reset" />
    <div class="pa-2 relative-center w900">
      <table class="float-left">
        <tr>
          <td>アクセス数</td>
          <td>
            <v-checkbox
              v-model="allAccess"
              label="全て"
              color="blue"
              class="float-left px-4"
            ></v-checkbox>
            <v-checkbox
              v-model="topAccess"
              label="トップ"
              color="blue"
              class="float-left px-4"
            ></v-checkbox>
            <v-checkbox
              v-model="realEstateAccess"
              label="不動産"
              color="blue"
              class="float-left px-4"
            ></v-checkbox>
            <v-checkbox
              v-model="estateAccess"
              label="物件検索"
              color="blue"
              class="float-left px-4"
            ></v-checkbox>
            <div class="clear" />
          </td>
        </tr>
        <tr>
          <td>ユニークユーザ数</td>
          <td>
            <v-checkbox
              v-model="allUnique"
              label="全て"
              color="green"
              class="float-left px-4"
            ></v-checkbox>
            <v-checkbox
              v-model="topUnique"
              label="トップ"
              color="green"
              class="float-left px-4"
            ></v-checkbox>
            <v-checkbox
              v-model="realEstateUnique"
              label="不動産"
              color="green"
              class="float-left px-4"
            ></v-checkbox>
            <v-checkbox
              v-model="estateUnique"
              label="物件検索"
              color="green"
              class="float-left px-4"
            ></v-checkbox>
            <div class="clear" />
          </td>
        </tr>
      </table>
      <div class="text-right float-right">
        <div class="relative inline-block">
          <v-select
            v-model="term"
            :items="terms"
            outlined
            class="select_line"
          />
        </div>
      </div>
      <div class="clear" />
    </div>
    <div class="relative">
      <div
        class="h-100 w-100 absolute b-0 r-0 z-200 bg-white flex-center"
        v-if="none"
      >
        データがありません
      </div>

      <div
        class="line_graf_loading w-100 absolute b-0 r-0 z-200 flex-center"
        v-if="reset"
      >
        <div>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular
          ><br />
          loaing
        </div>
      </div>

      <lineChart
        class="line_chart relative-center"
        :allAccess="allAccess"
        :topAccess="topAccess"
        :realEstateAccess="realEstateAccess"
        :estateAccess="estateAccess"
        :allUnique="allUnique"
        :topUnique="topUnique"
        :realEstateUnique="realEstateUnique"
        :estateUnique="estateUnique"
        :term="term"
        :reset="reset"
        @resetFalse="resetFalse"
        @resetTrue="reset = true"
        @noneTrue="none = true"
        @noneFalse="none = false"
      />
    </div>
    <div class="mt-5" />
    <div class="float-left w800">
      <div class="float-right pt-10">
        <div
          @click="open(from, 'from')"
          class="pointer float-left inline-block px-2"
        >
          <v-text-field
            v-model="from"
            label="開始日"
            outlined
            class="noevent date_input"
          />
        </div>
        <div
          @click="open(to, 'to')"
          class="pointer float-left inline-block px-2"
        >
          <v-text-field
            v-model="to"
            label="終了日"
            outlined
            class="noevent date_input"
          />
        </div>
        <div class="clear" />
      </div>
      <div class="clear" />
      <v-data-table
        :headers="headers"
        :items="accessUrls"
        :footer-props="{
          'items-per-page-options': [50, 100],
        }"
        :items-per-page="100"
      ></v-data-table>
    </div>
    <div class="float-left w400">
      <circleChart class="circleChart relative" :devices="devices" />
    </div>
    <div class="clear" />

    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-date-picker
          @change="daySelect"
          v-model="day"
          locale="jp-ja"
          :day-format="(date) => new Date(date).getDate()"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import lineChart from "@/components/access/lineChart.vue";
import circleChart from "@/components/access/circleChart.vue";
import d from "@/d";
export default {
  data() {
    return {
      headers: [
        { text: "url", value: "url" },
        { text: "ユニークユーザ数", value: "unique" },
        { text: "アクセス数", value: "accesses" },
      ],
      accessUrls: [],
      from: "",
      to: "",
      day: "",
      togo: "",
      dialog: 0,
      allAccess: true,
      topAccess: true,
      realEstateAccess: true,
      estateAccess: true,
      allUnique: false,
      topUnique: false,
      realEstateUnique: false,
      estateUnique: false,
      terms: ["日別", "月別", "年別"],
      term: "日別",
      reset: false,
      none: false,
      devices: [],
    };
  },

  components: {
    lineChart,
    circleChart,
  },
  mounted() {
    this.dataSet();
  },
  methods: {
    async dataSet() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      this.from =
        String(year) +
        "-" +
        String(("00" + month).slice(-2)) +
        "-" +
        String(("00" + day).slice(-2));
      this.to = this.from;

      this.changeTerm();
    },
    open(val, num) {
      this.day = val;
      this.togo = num;
      this.dialog = 1;
    },
    daySelect() {
      this.$data[this.togo] = this.day;
      this.dialog = 0;
    },
    async changeTerm() {
      const data = {
        from: this.from,
        to: this.to,
      };
      const response = await axios.post("/access/count", data);
      const accessUrls = response.data.accessUrls;
      this.devices = response.data.devices;

      this.accessUrls = accessUrls.sort((a, b) => b.accesses - a.accesses);
    },
    async resetFalse() {
      await d.sleep(500);
      this.reset = false;
    },
  },
  watch: {
    from() {
      this.changeTerm();
    },
    to() {
      this.changeTerm();
    },
    term() {
      this.reset = true;
      this.allAccess = true;
      this.topAccess = true;
      this.realEstateAccess = true;
      this.estateAccess = true;
      this.allUnique = false;
      this.topUnique = false;
      this.realEstateUnique = false;
      this.estateUnique = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/access.scss";
</style>