<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  data: () => ({
    chartdata: {
        labels: ["スマホ", "タブレット", "パソコン"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#FF6384", "#36A2EB", "#FFD54F"],
            data: [0,0,0],
          },
        ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  props: {
    devices: Array,
  },
  watch:{
    devices(){
        this.chartdata.datasets[0].data=this.devices
        this.renderChart(this.chartdata, this.options);
    }
  }
};
</script>